import type AuthButtonLink from '@/interfaces/AuthButtonLink'
import linkTo from '@/modules/linkTo'
import logoutRequest from '@/modules/logoutRequest'

const links: AuthButtonLink[] = [
    {
        name: Lang.get('common.profile'),
        description: Lang.get('common.profile_description'),
        href: linkTo('spec/profile'),
        slug: 'profile',
        icon: 'lni-user',
        action: null,
    },
    {
        name: Lang.get('common.logout'),
        description: Lang.get('common.logout_description'),
        href: '#',
        slug: 'logout',
        icon: 'lni-shift-left',
        action: () => logoutRequest(),
    }
]

export default links
